@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  max-width: 1440px;
  margin: 0 auto;

  @include media-breakpoint-up(lg) {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
}

.root {
  overflow: auto;
  height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
  @include backgroundImages('../pages/SortingHatIntro/assets/background', 'jpg');
}

.imageContainerDesktop {
  display: none;

  @include media-breakpoint-up(lg) {
    width: 50%;
    margin-top: 0;
    display: block;
  }

  img {
    width: 100%;
  }
}

.imageContainerMobile {
  margin-left: auto;
  margin-right: auto;
  max-width: 80%;
  margin-top: 30px;

  @include media-breakpoint-up(lg) {
    display: none;
  }

  img {
    width: 100%;
    @include media-breakpoint-down(lg) {
      max-height: 500px;
    }
  }
}

.textContainer {
  color: $white;
  display: flex;
  flex-direction: column;
  margin-right: 20px;
  margin-left: 20px;
  margin-top: em(10);

  @include media-breakpoint-up(lg) {
    margin-top: 0px;
    width: 50%;
    order: 1;
  }
}

.logoContainer {
  width: $logoWidthMobile;
  height: $logoHeightMobile;
  margin: 20px auto;

  @include media-breakpoint-up(lg) {
    width: $logoWidth;
    height: $logoHeight;
    margin: 10px auto 0;
  }

  .logo {
    display: none;
    @include media-breakpoint-up(lg) {
      margin: 10px auto;
      display: block;
    }
  }
}

.eyebrow {
  font-family: $sofiapro;
  font-weight: 400;
  text-transform: uppercase;
  font-style: normal;
  font-size: em(16);
  line-height: 20px;
  text-align: center;

  @include media-breakpoint-up(lg) {
    font-size: em(16);
    line-height: 18px;
    margin-top: 30px;
  }
}

.textContent {
  @include media-breakpoint-down(lg) {
    max-width: 550px;
    margin: 0 auto;
  }
}

.title {
  font-family: $harrybeastdisplay;
  font-size: em(40);
  font-weight: 400;
  line-height: 42px;
  text-align: center;
  margin-top: 5px;

  @include media-breakpoint-up(lg) {
    font-size: em(60);
    line-height: 65px;
    margin-top: 25px;
    margin-right: 30px;
    margin-left: 30px;
  }
}

.description {
  font-size: 12px;
  line-height: 20px;
  font-family: $sofiapro;
  text-align: center;
  margin-top: em(18);
  width: 70%;
  margin-left: auto;
  margin-right: auto;

  @include media-breakpoint-up(lg) {
    font-size: em(16);
    line-height: 24px;
    display: block;
    margin-top: 20px;
  }
}

.cta {
  margin: em(10);
  text-align: center;
  margin-top: em(20);
  @include media-breakpoint-up(lg) {
    margin-top: em(30);
  }
}

.reverse {
  display: flex;
  flex-direction: column-reverse;

  @include media-breakpoint-up(lg) {
    flex-direction: column;
  }
}
